Nav{
    background-color:white;
}

.logo {
    width:300px;
}

.site-logo {
    color:#14171c;
    font-size:18pt;
    font-family:"Montserrat", sans-serif;
    font-weight:900;
    display:inline;
}

.site-logo:hover {
    filter:opacity(80%);
}

.nav-link{
	font-family:Montserrat;
	font-weight:800;
	font-size:13px;
	color:#1e1e1e;
	text-transform:uppercase;
}