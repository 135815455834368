body {
  margin: 0;
  padding: 0%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.link-2{
  font-family:"Montserrat";
  font-weight:"bold";
  font-size:"13px";
  color:"#1e1e1e";
  text-transform:"uppercase"
  }