.button{
  color:rgb(255, 255, 255) !important;
  font-size:12pt !important;
  font-family:Montserrat,sans-serif !important;
  border:none !important;
  background-color: #215a59 !important;
  padding:12px 20px !important;
}

.cont{
  padding-top:15px;
}

.title{
  padding:20px;
  text-align:center;
  color:#54595f;
  font-family:"Montserrat", sans-serif;
}

.title-page{
  font-size:31px;
  font-weight:600;
}

.title-description{
  font-size:18px;
  font-weight:normal;
}

.sub-login{
	font-size:18px;
	font-family:Montserrat;
}

.sub-login-ph{
	font-size:18px;
	font-family:Montserrat;
}