.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


header{
  background-color:black;
  height:400px;
  margin:0px;
  padding:0px;
  position: relative;
  text-align: center;
  color: white;
  
  }
  
  .site-cover{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit:cover;
  z-index:0;
  filter: opacity(50%)
  }
  
.page-title{

  font-family:"Montserrat", sans-serif;
  font-weight: 600;
  font-size:3em;
}

.page-title-description{

  font-family:"Montserrat", sans-serif;
  font-weight: 400;
  font-size:2em;
  padding:5px;
}

.position-title {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.content{
  flex: 1 0 auto;
  padding-bottom: 10px;
}