@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  padding: 0%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.link-2{
  font-family:"Montserrat";
  font-weight:"bold";
  font-size:"13px";
  color:"#1e1e1e";
  text-transform:"uppercase"
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


header{
  background-color:black;
  height:400px;
  margin:0px;
  padding:0px;
  position: relative;
  text-align: center;
  color: white;
  
  }
  
  .site-cover{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit:cover;
  z-index:0;
  -webkit-filter: opacity(50%);
          filter: opacity(50%)
  }
  
.page-title{

  font-family:"Montserrat", sans-serif;
  font-weight: 600;
  font-size:3em;
}

.page-title-description{

  font-family:"Montserrat", sans-serif;
  font-weight: 400;
  font-size:2em;
  padding:5px;
}

.position-title {
  position: absolute;
  top: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}

.content{
  flex: 1 0 auto;
  padding-bottom: 10px;
}
Nav{
    background-color:white;
}

.logo {
    width:300px;
}

.site-logo {
    color:#14171c;
    font-size:18pt;
    font-family:"Montserrat", sans-serif;
    font-weight:900;
    display:inline;
}

.site-logo:hover {
    -webkit-filter:opacity(80%);
            filter:opacity(80%);
}

.nav-link{
	font-family:Montserrat;
	font-weight:800;
	font-size:13px;
	color:#1e1e1e;
	text-transform:uppercase;
}
.button-2{
    color:#191919 !important;
    font-size:18pt !important;
    font-family:Montserrat,sans-serif !important;
    border:none !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    padding:15px 0px !important;
  }

  .button-2:hover{
    -webkit-filter:opacity(80%);
            filter:opacity(80%);
    box-shadow: 0 1px 20px 0 rgba(46, 46, 46, 0.423);
  }
  
  .cont{
    padding-top:15px;
  }
  
  .title{
    padding:20px;
    text-align:center;
    color:#54595f;
    font-family:"Montserrat", sans-serif;
  }
  
  .title-page{
    font-size:31px;
    font-weight:600;
  }
  
  .title-description{
    font-size:18px;
    font-weight:normal;
  }

  .cont-2{
    text-align: center;
  }

  .margin-row{
    padding:10px 0px;
  }

  .img-button{
    width:75px !important;
    height:75px;
    margin-left:40px;
    margin-right:40px;
  }

  .button-text{
    text-align: left !important;
    vertical-align: middle !important;
    bottom:0px;
  }

  .col1{
    width:100%;
    height:100%;
    text-align: left;
  }

  .img-istituzionali{
    margin-top:40px;
    margin-bottom:40px;
  }

  .link-img:hover{
    -webkit-filter:opacity(70%);
            filter:opacity(70%);
  }
.button{
  color:rgb(255, 255, 255) !important;
  font-size:12pt !important;
  font-family:Montserrat,sans-serif !important;
  border:none !important;
  background-color: #215a59 !important;
  padding:12px 20px !important;
}

.cont{
  padding-top:15px;
}

.title{
  padding:20px;
  text-align:center;
  color:#54595f;
  font-family:"Montserrat", sans-serif;
}

.title-page{
  font-size:31px;
  font-weight:600;
}

.title-description{
  font-size:18px;
  font-weight:normal;
}

.sub-login{
	font-size:18px;
	font-family:Montserrat;
}

.sub-login-ph{
	font-size:18px;
	font-family:Montserrat;
}
.fullscreenMap {
    height: 80vh;
    width: auto;
}

.button-map{
    color:rgb(255, 255, 255) !important;
    font-size:12pt !important;
    font-family:Montserrat,sans-serif !important;
    border:none !important;
    background-color: #215a59 !important;
    padding:12px 20px !important;
    float:right;
  }

  .riga-barca{
    color:#191919 !important;
    font-size:18pt !important;
    font-family:Montserrat,sans-serif !important;
    border:none !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    padding:15px 15px !important;
  }

  .riga-barca:hover{
    -webkit-filter:opacity(80%);
            filter:opacity(80%);
    box-shadow: 0 1px 20px 0 rgba(46, 46, 46, 0.423);
  }

  .list-item{
    background-color: rgba(240, 248, 255, 0);
    border:none;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
body{
  min-height: 100vh;
  width: 100%;
  background: #EEECEB;
}

.footerLogo{
    height: 1em;
    margin-right: 15px;
}
.footer {
    flex-shrink: 0;
    text-align: left;
    background-color: white;
  }

footer{
  background: #006e89;
  width: 100%;
}

footer .footer-content{
  max-width: 1250px;
  margin: auto;
  padding: 30px 40px 40px 40px;
}
footer .footer-content .top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.footer-content .top .logo-details{
  color: #000;
  font-size: 30px;
}
.footer-content .top .media-icons{
  display: flex;
}
.footer-content .top .media-icons a{
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #000;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.top .media-icons a:nth-child(1){
  background: #4267B2;
}
.top .media-icons a:nth-child(1):hover{
  color: #4267B2;
  background: #fff;
}
.top .media-icons a:nth-child(2){
  background: #1DA1F2;
}
.top .media-icons a:nth-child(2):hover{
  color: #1DA1F2;
  background: #fff;
}
.top .media-icons a:nth-child(3){
  background: #E1306C;
}
.top .media-icons a:nth-child(3):hover{
  color: #E1306C;
  background: #fff;
}
.top .media-icons a:nth-child(4){
  background: #0077B5;
}
.top .media-icons a:nth-child(4):hover{
  color: #0077B5;
  background: #fff;
}
.top .media-icons a:nth-child(5){
  background: #FF0000;
}
.top .media-icons a:nth-child(5):hover{
  color: #FF0000;
  background: #fff;
}
footer .footer-content .link-boxes{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
footer .footer-content .link-boxes .box{
  width: calc(100% - 10px);
}
.footer-content .link-boxes .box .link_name{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}

.footer-content .link-boxes .box li{
  margin: 6px 0;
  list-style: none;
}
.footer-content .link-boxes .box li a{
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease
}
.footer-content .link-boxes .box li a:hover{
  opacity: 1;
  text-decoration: underline;
}
.footer-content .link-boxes .input-box{
  margin-right: 55px;
}
.link-boxes .input-box input{
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #AFAFB6;
  background: #140B5C;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #000;
  margin-top: 5px;
}
.link-boxes .input-box input::-webkit-input-placeholder{
  color: #AFAFB6;
  font-size: 16px;
}
.link-boxes .input-box input:-ms-input-placeholder{
  color: #AFAFB6;
  font-size: 16px;
}
.link-boxes .input-box input::placeholder{
  color: #AFAFB6;
  font-size: 16px;
}
.link-boxes .input-box input[type="button"]{
  background: #fff;
  color: #140B5C;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}
.input-box input[type="button"]:hover{
  opacity: 1;
}
footer .bottom-details{
  width: 100%;
  background: #215a59;
}
footer .bottom-details .bottom_text{
  max-width: 1250px;
  margin: auto;
  background-color: #215a59;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a{
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.bottom-details .bottom_text a:hover{
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a{
  margin-right: 10px;
}
@media (max-width: 900px) {
  footer .footer-content .link-boxes{
    flex-wrap: wrap;
  }
  footer .footer-content .link-boxes .input-box{
    width: 40%;
    margin-top: 10px;
  }
}
@media (max-width: 700px){
  footer{
    position: relative;
  }
  .footer-content .top .logo-details{
    font-size: 26px;
  }
  .footer-content .top .media-icons a{
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  footer .footer-content .link-boxes .box{
    width: calc(100% - 10px);
  }
  footer .footer-content .link-boxes .input-box{
    width: 60%;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 12px;
  }
}
@media (max-width: 520px){
  footer::before{
    top: 145px;
  }
  footer .footer-content .top{
    flex-direction: column;
  }
  .footer-content .top .media-icons{
    margin-top: 16px;
  }
  footer .footer-content .link-boxes .box{
    width: calc(100% - 10px);
  }
  footer .footer-content .link-boxes .input-box{
    width: 100%;
  }
}

