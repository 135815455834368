.button-2{
    color:#191919 !important;
    font-size:18pt !important;
    font-family:Montserrat,sans-serif !important;
    border:none !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    padding:15px 0px !important;
  }

  .button-2:hover{
    filter:opacity(80%);
    box-shadow: 0 1px 20px 0 rgba(46, 46, 46, 0.423);
  }
  
  .cont{
    padding-top:15px;
  }
  
  .title{
    padding:20px;
    text-align:center;
    color:#54595f;
    font-family:"Montserrat", sans-serif;
  }
  
  .title-page{
    font-size:31px;
    font-weight:600;
  }
  
  .title-description{
    font-size:18px;
    font-weight:normal;
  }

  .cont-2{
    text-align: center;
  }

  .margin-row{
    padding:10px 0px;
  }

  .img-button{
    width:75px !important;
    height:75px;
    margin-left:40px;
    margin-right:40px;
  }

  .button-text{
    text-align: left !important;
    vertical-align: middle !important;
    bottom:0px;
  }

  .col1{
    width:100%;
    height:100%;
    text-align: left;
  }

  .img-istituzionali{
    margin-top:40px;
    margin-bottom:40px;
  }

  .link-img:hover{
    filter:opacity(70%);
  }