.button-map{
    color:rgb(255, 255, 255) !important;
    font-size:12pt !important;
    font-family:Montserrat,sans-serif !important;
    border:none !important;
    background-color: #215a59 !important;
    padding:12px 20px !important;
    float:right;
  }

  .riga-barca{
    color:#191919 !important;
    font-size:18pt !important;
    font-family:Montserrat,sans-serif !important;
    border:none !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    padding:15px 15px !important;
  }

  .riga-barca:hover{
    filter:opacity(80%);
    box-shadow: 0 1px 20px 0 rgba(46, 46, 46, 0.423);
  }

  .list-item{
    background-color: rgba(240, 248, 255, 0);
    border:none;
}
